import "./App.css";
import { VIDs } from "./assets/vids";

function App() {
  return (
    <div className="app-container">
      <div className="cs-container">
        <p className="cs-text">
          <span className="text-dark">Dev</span>
          <span className="text-light">Trove (SMC-PVT)</span>
        </p>
        <p className="cs-desc">Comming Soon</p>
        <div className="cs-logo-mask"></div>
        {/* <video
          autoPlay
          controls={false}
          loop
          muted
          contr
          width={"60%"}
          height={"60%"}
          className="video"
        >
          <source src={VIDs.FBLogoAnimation} type="video/mp4" />
        </video> */}
      </div>
    </div>
  );
}

export default App;
